import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from '../Lib/API'
import { useNavigate } from 'react-router-dom'
import { designerActions } from '../Store/designer'
import { globalActions } from '../Store/global'
import { FaUpload } from 'react-icons/fa'
import toast from 'react-hot-toast'

function GettingStarted() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const global = useSelector(state => state.global)
    const [registerForm, setRegisterForm] = useState({
        fullName: "",
        email: "",
        phoneNumber: global?.phoneNumber,
        address: "",
        area: "",
        city: "",
        gst: "",
        image: "",
        bank: "",
        accountNumber: "",
        ifscCode: "",
        type: ""
      });
    function validForm(){
      if(!registerForm.fullName){
        toast.error("Enter Name")
        return false
      } else if(!registerForm.email){
        toast.error("Enter an Email")
        return false
      } else if(!registerForm.image){
        toast.error("Upload a Logo")
        return false
      } else {
        return true
      }
    }
    async function loadDesigner(){
      if(!validForm()){
        return
      }
        await API.post("/designer/create",registerForm,{ headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const {phoneNumber, fullName ,email, image } = res.data
            if(phoneNumber && fullName && email && image){
                dispatch(globalActions.setGettingStarted(false))
                dispatch(designerActions.addDesigner(res.data))
                navigate('/home')
            }
        })
    }
    const registerFormChange = (e) => {
        setRegisterForm({
          ...registerForm,
          [e.target.id]: e.target.value
        });
      };
      const handleImageChange = (e) => {
        setRegisterForm(prevState => ({
          ...prevState,
          image: e.target.files[0]
        }));
      };
return (
    <div className='flex flex-col items-center mt-4'>
        <p className='font-bold text-2xl'>Getting Started</p>
        <div className='w-72 bg-gray-400 h-[1px] my-3'></div>
        <div className='w-72 flex justify-evenly items-center my-3'>
          <div className='flex justify-center items-center' onClick={()=>{setRegisterForm({...registerForm,type:'individual'})}}>
          {registerForm.type == 'individual' ? <span className='w-[20px] h-[20px] mx-2 rounded-full bg-[#be9682]'></span> : <span className='w-[20px] h-[20px] mx-2 rounded-full border-2 border-black'></span>}
          Individual
          </div>
          <div className='flex justify-center items-center' onClick={()=>{setRegisterForm({...registerForm,type:'organization'})}}>
          {registerForm.type == 'organization' ? <span className='w-[20px] h-[20px] mx-2 rounded-full bg-[#be9682]'></span> : <span className='w-[20px] h-[20px] mx-2 rounded-full border-2 border-black'></span>}
          Organization
          </div>
        </div>
        { registerForm.type == "" ? "" :
        <div className="grid gap-2 px-4">
          <div className='border-[1px] border-gray-300 grid gap-4 p-2 rounded-lg mb-2'>
            <p className='text-[12px] text-right text-red-500'>* Required Fields</p>
            <div className="relative">
              <input type="text" id="phoneNumber" className="form-input peer hover:cursor-not-allowed" disabled value={global?.phoneNumber} readOnly placeholder=" " />
              <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            </div>
            <div className="relative">
              <input type="text" id="fullName" className="form-input peer" value={registerForm.fullName} onChange={registerFormChange} placeholder=" " />
              <label htmlFor="fullName" className="form-label required">Name</label>
            </div>
            <div className="relative">
              <input type="text" id="email" className="form-input peer" value={registerForm.email} onChange={(e) => registerFormChange(e)} placeholder=" " />
              <label htmlFor="email" className="form-label required">Email</label>
            </div>
            <label htmlFor="logoInput" className='flex py-2 my-2 w-full rounded-lg items-center justify-center bg-blue-200 font-extralight text-blue-600 hover:font-medium hover:text-white hover:bg-blue-600 hover:transition-all hover:duration-300 hover:ease-in required'><FaUpload className='w-8 h-8 mr-2'/>{registerForm.image?registerForm.image.name:"Upload Logo"}</label>
            <input hidden onChange={handleImageChange} type="file" accept="image/*" id='logoInput'/>
          </div>
            <div className="relative">
              <textarea type="text" id="address" className="form-input peer" value={registerForm.address} onChange={registerFormChange} placeholder=" " />
              <label htmlFor="address" className="form-label ">Address</label>
            </div>
            <div className='grid grid-flow-col gap-4'>
              <div className="relative">
                <input type="text" id="area" className="form-input peer" value={registerForm.area} onChange={(e) => registerFormChange(e)} placeholder=" " />
                <label htmlFor="area" className="form-label ">Area</label>
              </div>
              <div className="relative">
                <input type="text" id="city" className="form-input peer" value={registerForm.city} onChange={(e) => registerFormChange(e)} placeholder=" " />
                <label htmlFor="city" className="form-label ">City</label>
              </div>
            </div>
            
            <label htmlFor="bankName" className="block text-sm font-medium text-gray-900">Select a Bank</label>
            <select onChange={e => setRegisterForm(prev => {
              return {...prev,bank:e.target.value}
              })} id="bankName" className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <option value="">Bank Name</option>
              <option value="hdfc">HDFC Bank</option>
              <option value="sbi">SBI Bank</option>
              <option value="icici">ICICI Bank</option>
              <option value="others">Others</option>
            </select>
            <div className="relative">
              <input type="text" id="accountNumber" className="form-input peer" value={registerForm.accountNumber} onChange={registerFormChange} placeholder=" " />
              <label htmlFor="accountNumber" className="form-label ">Account Number</label>
            </div>

            <div className="relative">
              <input type="text" id="ifscCode" className="form-input peer" value={registerForm.ifscCode} onChange={registerFormChange} placeholder=" " />
              <label htmlFor="ifscCode" className="form-label">IFSC Code</label>
            </div>

            <div className='flex mt-5 mb-4 justify-center'>
                <button
                onClick={loadDesigner}
                className="bg-[#be9682] hover:bg-white hover:border-2 hover:text-[#be9682] text- w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                <p className="mx-6">Submit</p>
            </button>
            </div>
        </div>
        }
    </div>
  )
}

export default GettingStarted